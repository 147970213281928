<template>
    <!-- PC端 -->
    <div class="page pc">
        <!-- 轮播图尺寸 1366*625 -->
        <div class="banner">
            <div class="banner-carousel">
                <el-carousel trigger="click" :autoplay="true" arrow="always">
                    <el-carousel-item v-for="banner in bannerImages" v-bind:key="banner.bannerId">
                        <div class="banner-item" @click="goToBannerText(banner)">
                            <img v-bind:src="$resources.autoTransform(banner.imgUrl)" />
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div class="product-cards" v-animate.scroll="{className: 'fadeInUp', scrollBottom: 0}">
                <div class="product-card" v-for="item in mainItems" v-bind:key="item.itemId">
                    <router-link class="product-img" style="cursor:pointer" :to="`/product/product-detail?productId=${item.itemId}&product=${item.name}`">
                        <img style="height: 100px; width: 100px;" v-bind:src="$resources.autoTransform(item.coverImgUrl)" />
                    </router-link>
                    <router-link class="product-profile" style="cursor:pointer" :to="`/product/product-detail?productId=${item.itemId}&product=${item.name}`">
                        <div class="product-title" style="word-break: break-all; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;height: 50px;">
                          {{item.name}}
                        </div>
                        <div class="product-desc" v-html="item.intro"
                             style="word-break: break-all; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- 视频 -->
        <div class="profile-video">
            <div class="video-content">
                <div v-if="!showVideo" class="video-cover" v-on:click="showVideo = true">
                    <img v-bind:src="$resources.autoTransform($resources.Image.static['u171.jpg'])" />
                </div>
                <div v-else class="video">
                  <video v-bind:src="$resources.Video.static['u171.mp4']" controls autoplay style="width:100%; height: 100%;">
                    您的浏览器不支持 video 标签。
                  </video>
                </div>
            </div>
        </div>

        <!-- 公司相关 -->
        <div class="profiles">
          <div class="company-profile">
            <div class="title opacity-hidden" v-animate.scroll="'fadeInUp'">
              {{$t(companyCarouselTitle)}}
            </div>
            <el-carousel ref="profileCarousel" :autoplay="true" :interval="5000" arrow="always"
                         @change="(idx) => {companyCarouselTitle = companyCarouselTitles[idx]}" indicator-position="none">
              <el-carousel-item>
                <div class="content">
                  <div class="image opacity-hidden" v-animate.scroll="'fadeInLeft'" style="max-height: 500px;max-width: 588px;">
                      <aspect-ratio ratio="85">
                          <img v-bind:src="$resources.autoTransform($resources.Image.static['u169.jpg'])"  style="max-height: 500px;max-width: 588px;"/>
                      </aspect-ratio>
                  </div>
                  <div class="info opacity-hidden" v-animate.scroll="'fadeInRight'">
                    <span class="name">{{$t('layout.companyFullName')}}</span>
                    <span class="sub-title">{{$t('home.companyAbout')}}</span>
                    <span class="desc">{{$t('home.companyProfileContent')}}</span>
                    <router-link to="/company/profile" class="more">More</router-link>
                  </div>
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <!-- 企业文化 -->
                <div class="content">
                  <div class="image" style="max-height: 500px;max-width: 588px;">
                      <aspect-ratio ratio="85">
                          <img v-bind:src="$resources.autoTransform($resources.Image.static['u775.jpg'])" style="max-height: 500px;max-width: 588px;"/>
                      </aspect-ratio>
                  </div>
                  <div class="info" style="min-width: 500px">
                    <span class="name">{{$t('layout.companyFullName')}}</span>
                    <span class="sub-title"> {{$t('home.companyCulture')}}</span>
                    <span class="desc" v-html="$t('home.companyCultureHtml')"></span>
                    <router-link to="/company/culture" class="more">More</router-link>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <!-- 应用领域 -->
        <div class="apply-field">
            <div class="title opacity-hidden" v-animate.scroll="'fadeInUp'">
                {{$t('home.applicationArea')}}
            </div>
            <div class="content">
                <div class="left">
                    <!-- 保持宽高比 370 * 545 -->
                    <aspect-ratio ratio="139.74">
                        <div class="field opacity-hidden" v-animate.scroll="'fadeInLeft'">
                            <img v-bind:src="$resources.autoTransform($resources.Image.static['u88.png'])" />
                            <div class="mask" v-if="$i18n.locale === 'zh-CN'">
                                <span>化工行业</span>
                                <span>Chemical Industry</span>
                            </div>
                            <div class="mask" v-else>
                                <span>Chemical Industry</span>
                            </div>
                        </div>
                    </aspect-ratio>
                </div>
                <div class="right">
                    <!-- 保持宽高比 780 * 545 -->
                    <aspect-ratio ratio="69.87">
                        <div class="field opacity-hidden" v-animate.scroll="'fadeInDown'">
                            <img v-bind:src="$resources.autoTransform($resources.Image.static['u90.png'])" />
                            <div class="mask" v-if="$i18n.locale === 'zh-CN'">
                                <span>水泥行业</span>
                                <span>Cement Industry</span>
                            </div>
                            <div class="mask" v-else>
                                <span>Cement Industry</span>
                            </div>
                        </div>
                        <div class="field opacity-hidden" v-animate.scroll="'fadeInRight'">
                            <img v-bind:src="$resources.autoTransform($resources.Image.static['u92.png'])" />
                            <div class="mask" v-if="$i18n.locale === 'zh-CN'">
                                <span>冶铁行业</span>
                                <span>The iron smelting industry</span>
                            </div>
                            <div class="mask" v-else>
                                <span>The iron smelting industry</span>
                            </div>
                        </div>
                        <div class="field opacity-hidden" v-animate.scroll="'fadeInUp'">
                            <img v-bind:src="$resources.autoTransform($resources.Image.static['u170.jpg'])" />
                            <div class="mask" v-if="$i18n.locale === 'zh-CN'">
                                <span>垃圾焚烧行业</span>
                                <span>Waste Incineration Industry</span>
                            </div>
                            <div class="mask" v-else>
                                <span>Waste Incineration Industry</span>
                            </div>
                        </div>
                        <div class="field opacity-hidden" v-animate.scroll="'fadeInRight'">
                            <img v-bind:src="$resources.autoTransform($resources.Image.static['u168.jpg'])" />
                            <div class="mask" v-if="$i18n.locale === 'zh-CN'">
                                <span>电力行业</span>
                                <span>Electric Power Industry</span>
                            </div>
                            <div class="mask" v-else>
                                <span>Electric Power Industry</span>
                            </div>
                        </div>
                    </aspect-ratio>
                </div>
            </div>
        </div>

        <!-- 新闻资讯 -->
        <div class="news">
            <div class="title opacity-hidden" v-animate.scroll="'fadeInDown'">
                {{$t('layout.news')}}
            </div>
            <div class="content">
                <div class="preview-news opacity-hidden" v-animate.scroll="'fadeInLeft'">
                    <!-- 新闻图片400*270 -->
                    <div class="preview-news-image">
                        <img style="height: 100%;width: 100%;object-fit:cover;" v-bind:src="$resources.autoTransform(coverNews.coverImgUrl)" />
                    </div>
                    <div class="preview-news-info">
                        <span class="news-time">
                            {{coverNews.type === '公司要闻' ? $t('layout.companyNews') : $t('layout.industryNews')}} | {{coverNews.gmtCreated}}
                        </span>
                        <span class="news-title">
                            {{$i18n.locale === 'zh-CN' ? coverNews.zhTitle : coverNews.enTitle}}
                        </span>
                        <router-link tag="span" class="more" :to="`/news/news-detail?newsId=${coverNews.newsId}`">
                            Detail
                        </router-link>
                    </div>
                </div>
                <div class="news-list">
                    <div v-for="n in news" v-bind:key="n.newsId"
                         class="news-item opacity-hidden" v-animate.scroll="'fadeInRight'">
                        <span class="news-time">
                            {{n.type === '公司要闻' ? $t('layout.companyNews') : $t('layout.industryNews')}} | {{n.gmtCreated}}
                        </span>
                        <router-link tag="span" class="news-title" :to="`/news/news-detail?newsId=${n.newsId}`">
                          {{$i18n.locale === 'zh-CN' ? n.zhTitle : n.enTitle}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import AspectRatio from '../../components/AspectRatio';

    export default {
      name: 'Home',

      components: {
        AspectRatio,
        'aspect-ratio': AspectRatio,
      },

      data: () => {
        return {
          bannerImages: [],

          coverNews: {},
          news: [],

          mainItems: [],

          showVideo: false,

          companyCarouselTitle: 'home.companyAbout',
          companyCarouselTitles: ['home.companyAbout', 'home.companyCulture']
        };
      },

      created() {
        window.vue = this;
        this.$cache.get('layout.items').then(items => {
          if (!items || items.length <= 0) {
            return [];
          } else {
            let mainItems = items;
            if (items && items.length > 3) {
              mainItems = items.slice(0, 3);
            }
            mainItems.forEach(i => {
              i.zhIntro = i.zhIntro.replace(/<\/?.+?>/g,"").trim();
              i.enIntro = i.enIntro.replace(/<\/?.+?>/g,"").trim();

              if (this.$i18n.locale === 'zh-CN') {
                i.name = i.zhName;
                i.intro = i.zhIntro;
              } else {
                i.name = i.enName;
                i.intro = i.enIntro;
              }
            });
            this.mainItems = mainItems;
          }
        });

        this.loadBanner();
        this.loadNews();
      },

      methods: {

        loadBanner() {
          this.$ajax.get('/carousel/items').then(response => {
            const banners = response.data;
            if (banners && banners.length > 0) {
              this.bannerImages = banners;
            } else {
              console.warn('Banner未设置！');
            }
          });
        },

        goToBannerText(banner) {
          if (!banner || !banner.isRoute) return;
          const title = this.$i18n.locale === 'zh-CN' ? banner.zhTitle : banner.enTitle;
          if (!title || title === '') {
            console.warn('banner不存在内容信息');
          } else {
            this.$router.push({
              path: '/article',
              query: {
                title,
                articleId: banner.bannerId,
                lang: this.$i18n.locale
              }
            });
          }
        },

        loadNews() {
          this.$ajax.get('/news/home').then(response => {
            const news = response.data;
            if (news && news.length > 0) {
              let showNews = [];
              news.forEach(n => {
                this.formatNewsMessage(n);
                if (n.isMain) this.coverNews = n;
                else showNews.push(n);
              });
              this.news = showNews;
            }
          });
        },

        formatNewsMessage(news) {
          const year = news.gmtCreated.substr(0, 4);
          const month = news.gmtCreated.substr(5, 2);
          const day = news.gmtCreated.substr(8, 2);
          news.gmtCreated = `${year}年${parseInt(month)}月${parseInt(day)}日`;
        },

      }
    }

</script>

<style lang="scss">
    .page.pc {
        background-color: var(--white);

        button.el-carousel__arrow {
          height: 64px;
          width: 64px;
          font-size: 36px;
          color: black;
        }

        .banner {
            max-width: 1366px;
            margin: 0 auto;

            .banner-carousel {
                width: 100%;
                height: 0;
                /* 保持宽高比 1366 * 625 */
                padding-top: 45.75%;
                position: relative;

                .el-carousel {
                    width: 100%;
                    height: 100% !important;
                    position: absolute;
                    top: 0;
                    left: 0;

                    .el-carousel__container {
                        height: 100%;
                    }

                    .banner-item {
                        width: 100%;
                        height: 0;
                        padding-top: 45.75%;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }

            .product-cards {
                display: flex;
                width: 100%;
                max-width: 990px;
                margin: 0 auto;
                box-shadow: rgba(1, 1, 1, .3) 0px 7px 50px 0px;
                position: relative;
                bottom: 70px;
                z-index: 15;

                .product-card {
                    display: flex;
                    flex: 1;
                    padding: 40px 30px;
                    background-color: var(--white);

                    &:first-child {
                        border-radius: 6px 0 0 6px;
                    }
                    &:last-child {
                        border-radius: 0 6px 6px 0;
                    }

                    .product-profile {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-left: 20px;

                        .product-title {
                            font-size: 18px;
                            font-weight: bold;
                        }
                        .product-desc {
                            margin-top: 10px;
                            color: var(--gray);
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .profile-video {
            width: 100%;
            max-width: 1366px;
            margin: 0 auto;
            padding-left: 30px;
            padding-right: 30px;

            .video-content {
                height: 0;
                /* 保持宽高比 1288 * 646 */
                padding-top: 50.16%;
                position: relative;

                .video-cover, .video {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;

                    video:focus {
                        outline: none;
                    }
                }
            }

            img, video {
                width: 100%;
                height: 100%;
            }
        }

        .profiles {

            .el-carousel__container {
                height: 500px;
                @media(max-width: 1020px) {
                    height: 450px;
                }
                @media(max-width: 910px) {
                    height: 380px;
                }
            }

            .company-profile {
                width: 100%;
                max-width: 1366px;
                margin: 0 auto;

                .title {
                    margin-top: 20px;
                    padding-left: 100px;
                    color: var(--black);
                    font-size: 38px;
                    font-weight: bolder;
                    line-height: 120px;
                }

                .content {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media(max-width: 1000px) {
                        padding: 0px 15px 0 15px;
                    }

                    .image {
                        width: 50%;
                        max-height: 500px;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 6px;
                        }
                    }
                    .info {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        flex: 1;
                        max-width: 480px;
                        padding: 50px 40px 40px 40px;
                        background-color: rgba(204, 204, 255, 1);
                        border-radius: 0 6px 6px 0;
                        color: var(--black);
                        @media(max-width: 910px) {
                            padding: 40px 16px 30px 16px;
                        }

                        .name {
                            font-size: 16px;
                        }
                        .sub-title {
                            font-size: 28px;
                            font-weight: 700;
                            line-height: 80px;
                            @media(max-width: 910px) {
                                font-size: 22px;
                                font-weight: 700;
                                line-height: 40px;
                            }
                        }
                        .desc {
                            opacity: 0.8;
                            font-size: 18px;
                            font-weight: 400;
                            text-align: justify;
                            word-break: break-all;
                            @media(max-width: 910px) {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .apply-field {
            width: 100%;
            max-width: 1366px;
            margin: 0 auto;

            .title {
                margin-top: 20px;
                padding-left: 100px;
                color: var(--black);
                font-size: 38px;
                font-weight: bolder;
                line-height: 120px;
            }

            .content {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 0 60px;

                .left {
                    flex: 1;
                    /*max-width: 370px;*/
                    padding-right: 30px;

                    .field {
                        width: 100%;
                        height: 100%;
                        .el-image {
                            height: 100%;
                        }
                    }
                }
                .right {
                    flex: 2;
                    /*max-width: 780px;*/

                    .inner {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .field {
                        width: calc(50% - 15px);
                        height: calc(50% - 15px);
                    }
                    .field:nth-child(1) {
                        margin-right: 15px;
                        margin-bottom: 15px;
                    }
                    .field:nth-child(2) {
                        margin-left: 15px;
                        margin-bottom: 15px;
                    }
                    .field:nth-child(3) {
                        margin-top: 15px;
                        margin-right: 15px;
                    }
                    .field:nth-child(4) {
                        margin-top: 15px;
                        margin-left: 15px;
                    }
                }
                .left, .right {
                    .field {
                        position: relative;
                        cursor: pointer;

                        &>img {
                            width: 100%;
                            height: 100%;
                            z-index: 10;
                        }

                        &:hover .mask {
                            opacity: 1;
                        }
                        .mask {
                            cursor: pointer;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            font-size: 20px;
                            color: var(--white);
                            background-color: rgba(1, 1, 1, .6);
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 15;
                            -webkit-transition: all linear .3s;
                            -moz-transition: all linear .3s;
                            -ms-transition: all linear .3s;
                            -o-transition: all linear .3s;
                            transition: all linear .3s;
                        }
                    }
                }
            }
        }

        .news {
            width: 100%;
            max-width: 1366px;
            margin: 0 auto;
            padding-bottom: 50px;

            .title {
                margin-top: 20px;
                padding-left: 100px;
                color: var(--black);
                font-size: 38px;
                font-weight: bolder;
                line-height: 120px;
            }

            .content {
                display: flex;
                justify-content: center;
                align-items: center;

                .preview-news {
                    width: 400px;

                    .preview-news-image {
                        height: 270px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 6px 6px 0 0;
                        }
                    }

                    .preview-news-info {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 30px 40px;
                        color: var(--black);
                        background-color: rgba(204, 204, 255, 1);
                        border-radius: 0 0 6px 6px;

                        .news-time {
                            font-size: 16px;
                            line-height: 40px;
                            opacity: 0.8;
                        }
                        .news-title {
                            font-size: 24px;
                            font-weight: 700;
                        }
                    }
                }

                .news-list {
                    flex: 1;
                    max-width: 600px;
                    margin-left: 100px;
                    color: var(--black);
                    @media screen and (max-width: 1080px) {
                        margin-left: 30px;
                    }

                    .news-item {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 40px;

                        .news-time {
                            font-size: 16px;
                            line-height: 40px;
                            position: relative;
                            opacity: 0.8;
                        }
                        .news-time:after {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 1px;
                            background-color: var(--gray);
                            position: absolute;
                            bottom: 0px;
                        }

                        .news-title {
                            margin-top: 8px;
                            font-size: 20px;
                            font-weight: 400;
                            cursor: pointer;
                        }
                    }

                    .news-item:last-child {
                        margin-bottom: 0;
                    }
                }
            }

        }

        .more {
            width: auto;
            margin-top: 30px;
            padding: 0 40px;
            font-size: 16px;
            line-height: 40px;
            border: 1px solid var(--black);
            border-radius: 6px;
            cursor: pointer;
            -webkit-transition: all linear .3s;
            -moz-transition: all linear .3s;
            -ms-transition: all linear .3s;
            -o-transition: all linear .3s;
            transition: all linear .3s;
            position: relative;

            &:after{
                content: '\e664';
                font-family: iconfont, serif;
                font-size: 20px;
                opacity: 0;
                -webkit-transition: all linear .3s;
                -moz-transition: all linear .3s;
                -ms-transition: all linear .3s;
                -o-transition: all linear .3s;
                transition: all linear .3s;

                position: absolute;
                top: 1px;
                right: 25px;
            }
            &:hover {
                padding-left: 30px;
                padding-right: 50px;
            }
            &:hover:after {
                opacity: 1;
                right: 18px;
            }
        }
    }
</style>
